const appConfig = {
     apiPrefix: 'https://crmbackend.devtechub.com/api/',
     //apiPrefix: 'http://localhost:5002/api/',
    authenticatedEntryPath: '/home',
    unAuthenticatedEntryPath: '/sign-in',
    tourPath: '/',
    enableMock: false,
	googleMapApiKey: 'AIzaSyC0os7Hf2Rbvn8cOrJEz4M_6_p0GXWa2mA'
}

export default appConfig
