import {ADMIN, USER} from 'constants/roles.constant'
export const signInUserData = [
    {
        id: '21',
        avatar: '/img/avatars/thumb-1.jpg',
        userName: 'Carolyn Perkins',
        email: 'admin@freight.com',
        authority: ['admin', 'user'],
        password: '123Qwe',
        accountUserName: 'admin',
        role:ADMIN
    },
    {
        id: '22',
        avatar: '/img/avatars/thumb-1.jpg',
        userName: 'Perkins User',
        email: 'user@freight.com',
        authority: ['user'],
        password: 'User@1234',
        accountUserName: 'userr',
        role:USER
    }
]